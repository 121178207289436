@mixin h2 {
	--color: var(--color-1);
	font-size: var(--font-size-1);
	font-weight: normal;
}

@mixin h3 {
	--color: var(--color-1);
	font-size: var(--font-size-2);
	font-weight: bold;
}

@mixin h4 {
	--color: var(--color-1);
	font-size: var(--font-size-2);
	font-weight: normal;
}

@mixin h5 {
	--color: var(--color-2);
	font-size: var(--font-size-3);
	font-weight: bold;
}

@mixin h6 {
	--color: var(--color-1);
	font-size: var(--font-size-3);
	font-weight: normal;
}

@mixin h7 {
	--color: var(--color-3);
	font-size: var(--font-size-3);
	font-weight: normal;
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
