/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

form {
	height: 100%;
}
/** Ionic CSS Variables -MW edit 22.11.23 **/
:root {
	--ion-color-primary: #257cff;
	--ion-color-primary-rgb: 37, 124, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #216de0;
	--ion-color-primary-tint: #3b89ff;

	--ion-color-secondary: #4ab0ff;
	--ion-color-secondary-rgb: 74, 176, 255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #419be0;
	--ion-color-secondary-tint: #5cb8ff;

	--ion-color-tertiary: #f45a2a;
	--ion-color-tertiary-rgb: 244, 90, 42;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #d74f25;
	--ion-color-tertiary-tint: #f56b3f;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #edeef2;
	--ion-color-light-rgb: 237, 238, 242;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d1d1d5;
	--ion-color-light-tint: #eff0f3;

	--ion-color-demo: #e2653b;
	--ion-color-demo-rgb: 226, 101, 59;
	--ion-color-demo-contrast: #ffffff;
	--ion-color-demo-contrast-rgb: 0, 0, 0;
	--ion-color-demo-shade: #c75934;
	--ion-color-demo-tint: #e5744f;

	--ion-color-explanation: #fff2cc;
	--ion-color-explanation-rgb: 255, 242, 204;
	--ion-color-explanation-contrast: #bf9000;
	--ion-color-explanation-contrast-rgb: 0, 0, 0;
	--ion-color-explanation-shade: #e0d5b4;
	--ion-color-explanation-tint: #fff3d1;

	--ion-color-acquisition: #fff2cc;
	--ion-color-acquisition-rgb: 255, 242, 204;
	--ion-color-acquisition-contrast: #bf9000;
	--ion-color-acquisition-contrast-rgb: 0, 0, 0;
	--ion-color-acquisition-shade: #e0d5b4;
	--ion-color-acquisition-tint: #fff3d1;

	--ion-color-portfolio: #d9ead3;
	--ion-color-portfolio-rgb: 217, 234, 211;
	--ion-color-portfolio-contrast: #38761d;
	--ion-color-portfolio-contrast-rgb: 0, 0, 0;
	--ion-color-portfolio-shade: #bfceba;
	--ion-color-portfolio-tint: #ddecd7;

	--ion-toolbar-background: var(--ion-color-primary);
	--ion-toolbar-color: #fff;
	--ion-background-color: #fff;

	--ion-grid-column-padding: 5px;
	--ion-grid-column-padding-xs: 3px;
}

.ion-color-demo {
	--ion-color-base: var(--ion-color-demo);
	--ion-color-base-rgb: var(--ion-color-demo-rgb);
	--ion-color-contrast: var(--ion-color-demo-contrast);
	--ion-color-contrast-rgb: var(--ion-color-demo-contrast-rgb);
	--ion-color-shade: var(--ion-color-demo-shade);
	--ion-color-tint: var(--ion-color-demo-tint);
}

.ion-color-explanation {
	--ion-color-base: var(--ion-color-explanation);
	--ion-color-base-rgb: var(--ion-color-explanation-rgb);
	--ion-color-contrast: var(--ion-color-explanation-contrast);
	--ion-color-contrast-rgb: var(--ion-color-explanation-contrast-rgb);
	--ion-color-shade: var(--ion-color-explanation-shade);
	--ion-color-tint: var(--ion-color-explanation-tint);
}

.ion-color-acquisition {
	--ion-color-base: var(--ion-color-acquisition);
	--ion-color-base-rgb: var(--ion-color-acquisition-rgb);
	--ion-color-contrast: var(--ion-color-acquisition-contrast);
	--ion-color-contrast-rgb: var(--ion-color-acquisition-contrast-rgb);
	--ion-color-shade: var(--ion-color-acquisition-shade);
	--ion-color-tint: var(--ion-color-acquisition-tint);
}

.ion-color-portfolio {
	--ion-color-base: var(--ion-color-portfolio);
	--ion-color-base-rgb: var(--ion-color-portfolio-rgb);
	--ion-color-contrast: var(--ion-color-portfolio-contrast);
	--ion-color-contrast-rgb: var(--ion-color-portfolio-contrast-rgb);
	--ion-color-shade: var(--ion-color-portfolio-shade);
	--ion-color-tint: var(--ion-color-portfolio-tint);
}

/*
	* Dark Colors
	* -------------------------------------------
	*/

body.dark {
	--ion-color-primary: #afccff;
	--ion-color-primary-rgb: 175, 204, 255;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #9ab4e0;
	--ion-color-primary-tint: #b7d1ff;

	--ion-color-secondary: #50c8ff;
	--ion-color-secondary-rgb: 80, 200, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #46b0e0;
	--ion-color-secondary-tint: #62ceff;

	--ion-color-tertiary: #6a64ff;
	--ion-color-tertiary-rgb: 106, 100, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #5d58e0;
	--ion-color-tertiary-tint: #7974ff;

	--ion-color-success: #2fdf75;
	--ion-color-success-rgb: 47, 223, 117;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #29c467;
	--ion-color-success-tint: #44e283;

	--ion-color-warning: #ffd534;
	--ion-color-warning-rgb: 255, 213, 52;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0bb2e;
	--ion-color-warning-tint: #ffd948;

	--ion-color-danger: #ff4961;
	--ion-color-danger-rgb: 255, 73, 97;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #e04055;
	--ion-color-danger-tint: #ff5b71;

	--ion-color-dark: #374151;
	--ion-color-dark-rgb: 55, 65, 81;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #303947;
	--ion-color-dark-tint: #4b5462;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-light: #222428;
	--ion-color-light-rgb: 34, 36, 40;
	--ion-color-light-contrast: #ffffff;
	--ion-color-light-contrast-rgb: 255, 255, 255;
	--ion-color-light-shade: #1e2023;
	--ion-color-light-tint: #383a3e;

	--color: #f4f5f7;
	--ion-text-color: #f4f5f7;
	--ion-text-color-rgb: 244, 245, 257;

	--ion-toolbar-background: #374151;

	--ion-background-color: #374151;
	--ion-background-color-rgb: 55, 65, 81;

	--ion-card-background: #374151;
}

/*
	* iOS Dark Theme
	* -------------------------------------------
	

.ios body.dark {
	--ion-border-color: #121212;

	--ion-color-step-50: #0d0d0d;
	--ion-color-step-100: #1a1a1a;
	--ion-color-step-150: #262626;
	--ion-color-step-200: #333333;
	--ion-color-step-250: #404040;
	--ion-color-step-300: #4d4d4d;
	--ion-color-step-350: #595959;
	--ion-color-step-400: #666666;
	--ion-color-step-450: #737373;
	--ion-color-step-500: #808080;
	--ion-color-step-550: #8c8c8c;
	--ion-color-step-600: #999999;
	--ion-color-step-650: #a6a6a6;
	--ion-color-step-700: #b3b3b3;
	--ion-color-step-750: #bfbfbf;md
	--ion-color-step-800: #cccccc;
	--ion-color-step-850: #d9d9d9;
	--ion-color-step-900: #e6e6e6;
	--ion-color-step-950: #f2f2f2;
}

.ios body.dark ion-modal {
	--ion-background-color: var(--ion-color-step-100);
	--ion-toolbar-background: var(--ion-color-step-150);
	--ion-toolbar-border-color: var(--ion-color-step-250);
}*/

/*
	* Material Design Dark Theme
	* -------------------------------------------
	*/

body.dark {
	--ion-border-color: #222222;

	--ion-color-step-50: #1e1e1e;
	--ion-color-step-100: #2a2a2a;
	--ion-color-step-150: #363636;
	--ion-color-step-200: #414141;
	--ion-color-step-250: #4d4d4d;
	--ion-color-step-300: #595959;
	--ion-color-step-350: #656565;
	--ion-color-step-400: #717171;
	--ion-color-step-450: #7d7d7d;
	--ion-color-step-500: #898989;
	--ion-color-step-550: #949494;
	--ion-color-step-600: #a0a0a0;
	--ion-color-step-650: #acacac;
	--ion-color-step-700: #b8b8b8;
	--ion-color-step-750: #c4c4c4;
	--ion-color-step-800: #d0d0d0;
	--ion-color-step-850: #dbdbdb;
	--ion-color-step-900: #e7e7e7;
	--ion-color-step-950: #f3f3f3;

	--ion-tab-bar-background: #1f1f1f;
}

ion-split-pane {
	--side-width: 300px;
}

ion-router-outlet ion-content {
	--background: #eee;
}

body {
	--color-1: #434343;
	--color-2: #4e2424;
	--color-3: #999999;

	--font-size-1: 17pt;
	--font-size-2: 12pt;
	--font-size-3: 10pt;

	--pos-color: #b7e1cd;
	--neg-color: #f4c7c3;

	--ion-card-background: white;
	--ion-background-color: white;
}

body.dark {
	--color-1: #bfbfbf;
	--color-2: #bfbfbf;
	--color-3: #d9d9d9;

	--ion-dark-background: #2b2b2b;
	--ion-card-background: var(--ion-dark-background);
	--ion-toolbar-background: var(--ion-dark-background);
	--ion-background-color: var(--ion-dark-background);

	--pos-color: #204e3b;
	--neg-color: #69241e;

	ion-router-outlet ion-content {
		--background: #000000;
	}
}
