@import "./mixin.scss";

/* Hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	margin: 0;
}
input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
}

ion-col.full-height {
	display: flex;
	flex-direction: column;

	& > * {
		flex-grow: 1;
	}
}

.h2 {
	@include h2;
}

.h3 {
	@include h3;
}

.h4 {
	@include h4;
}

.h5 {
	@include h5;
}

.h6 {
	@include h6;
}

.h7 {
	@include h7;
}

.datatable {
	.rdt_TableCol {
		@include h5;
	}

	.rdt_TableCol > div > div {
		white-space: normal;
		font-weight: bold;
	}

	.rdt_TableRow,
	.jWOtlo:not(:last-of-type) {
		border: none;
	}

	.rdt_TableCell {
		@include h6;

		.sub-tag {
			display: flex;
			flex-direction: column;
			max-width: 100%;

			span {
				@include ellipsis;

				&:nth-child(2) {
					@include h7;

					color: var(--color);
				}
			}
		}
	}

	.epcppQ,
	.gnBnkV {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}

	.rdt_Table,
	.rdt_TableHeadRow,
	.rdt_TableRow:nth-child(even) {
		background: none;
	}

	.rdt_TableRow:nth-child(odd) {
		background-color: var(--ion-color-light-tint);
	}
}

nav.rdt_Pagination {
	background: none;
	flex-direction: row-reverse;
}

ion-spinner {
	width: 100px;
	height: 100px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.spinner-container {
	position: fixed;
	z-index: 100;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	background: #8888;
	height: 100%;
	width: 100%;
}

.flex-spacer {
	flex-grow: 1;
}

.info-icon {
	position: relative;
	color: #616e7e;
	cursor: help;
}

.tooltip {
	max-width: min(25em, 40vw);
	min-width: 0;
	white-space: pre-line;
}

.dropdown {
	position: relative;
	font-size: 0.875rem;
	font-weight: normal;
	--x-offset: 1.5em;
	--y-offset: 1.5em;
	--x-origin: center;
	--y-origin: center;

	.dropdown-content {
		position: absolute;
		padding: 10px;
		filter: drop-shadow(1px 1px 3px);
		background: white;
		width: max-content;
		max-width: min(25em, 40vw);
		min-width: 0;
		z-index: 999999;
		white-space: pre-line;
		transform: scale(0);
		transition: transform 0.1s;
		transform-origin: var(--x-origin) var(--y-origin);

		&.dropdown-top {
			bottom: var(--y-offset);
			--y-origin: bottom;
		}

		&.dropdown-bottom {
			top: var(--y-offset);
			--y-origin: top;
		}

		&.dropdown-left {
			right: var(--x-offset);
			--x-origin: right;
		}

		&.dropdown-right {
			left: var(--x-offset);
			--x-origin: left;
		}
	}

	&.visible .dropdown-content {
		transform: scale(1);
	}
}

.value-display {
	display: flex;
	flex-direction: column;

	.label {
		@include h7;
	}

	.value {
		@include h4;
	}

	&.emph-value {
		.label {
			@include h4;
		}

		.value {
			@include h3;
		}
	}

	&.negative {
		.value {
			color: red !important;
		}
	}
	&.positive {
		.value {
			color: green !important;
		}
	}
}

ion-input.required {
	.label-text:after {
		content: " *";
		color: red;
		background-color: var(--ion-card-background);
	}
}

code {
	padding: 2px 4px;
	color: #1f1e24;
	background-color: #ececef;
	border-radius: 4px;
}

ion-card-header.tabs {
	margin-bottom: 0.5em;

	ion-card-title {
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;

		span {
			margin-right: 2em;
			margin-bottom: 0.25em;
			padding-bottom: 0.25em;
			border-bottom: 2px solid var(--ion-color-medium);
			cursor: pointer;

			&:hover {
				color: var(--ion-color-medium);
				border-color: var(--ion-color-medium-tint);
			}

			&.active {
				color: var(--ion-color-primary);
				border-color: var(--ion-color-primary);
			}
		}
	}
}

ion-card.card-badge {
	box-shadow: none;
	padding: 0.5em;
}
