@import "theme/mixin.scss";

ion-content.dashboard {
	ion-accordion > ion-item,
	ion-card-title {
		@include h2;
	}

	.metrics-base .value-display {
		padding-bottom: 1em;
	}

	ion-card.metrics-chart {
		display: flex;
		flex-direction: column;
		min-height: 0;

		div {
			display: flex;
			flex-grow: 1;
			height: 500px;
		}

		canvas {
			position: relative;
		}

		ion-card-subtitle {
			@include h4;
		}
	}

	.metrics-table,
	.metrics-apartment-section {
		ion-card-header {
			flex-direction: row;

			ion-icon {
				cursor: pointer;
			}
		}
		ion-grid {
			overflow: auto;
			margin-bottom: 1.25em;
		}
	}

	.metrics-base {
		ion-col {
			display: flex;
		}
	}

	.metrics-apartment {
		ion-card.metrics-apartment-section {
			hr {
				border: 1px solid var(--color);
				height: unset;
				margin: 0;
			}

			ion-row {
				@include h4;

				&.subgroup-header {
					@include h3;
				}

				&.metrics-space {
					margin-top: 1.25em;
				}
			}

			ion-col {
				padding: 0;
			}

			ion-row.subgroup-body ion-col {
				&:last-child {
					padding-left: 0.5em;
				}
			}
		}
		.dash-object-detail {
			float: right;
		}
		@media (max-width: 575px) {
			.dash-object-detail {
				float: left;
			}
			ion-row {
				padding-bottom: 10px !important;
			}
		}
		h2 {
			margin-top: 0;
			margin-bottom: 0;
		}

		ion-grid {
			padding: 0;
		}
	}
}
