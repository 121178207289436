@import "theme/mixin.scss";

.slides {
	height: 100%;
}

.swiper-slide {
	align-items: flex-start;
}

ion-card.portfolio-metrics {
	ion-grid,
	ion-col {
		padding: 0;
	}
}

ion-router-link.new-property {
	border: 3px dashed;
	border-radius: 10px;
	margin: 10px;
	background-color: #fff4;
	transition: background-color 0.25s;
	cursor: pointer;

	&:hover {
		background-color: #ffff;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1em;
		padding: 1em 0;
		height: 100%;
	}

	ion-icon {
		display: block;
		font-size: 3em;
	}

	ion-label {
		display: block;
		font-size: 2em;
	}
}

.emptyMsg {
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	margin-top: 20px;
}

ion-card.property-tile {
	display: flex;
	flex-direction: column;

	ion-card-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		ion-list {
			flex-grow: 1;
		}
	}

	ion-item {
		--padding-start: 0;
	}
}
