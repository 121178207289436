@import "theme/mixin.scss";

.create-edit-property {
	ion-accordion > ion-item,
	ion-card-title {
		@include h2;
	}

	ion-label {
		&.create-edit-radio-label {
			@include h3;
		}
	}

	ion-radio-group {
		@include h4;
	}

	p {
		@include h4;
	}

	.create-edit-input {
		@include h4;
		width: calc(100% - 3em);
		margin: 1em 0;

		.label-text-wrapper {
			margin-top: -5pt;
		}
	}

	.create-edit-input .input-bottom.sc-ion-input-md {
		padding: 0px;
	}

	.create-edit-input .input-bottom.sc-ion-input-md {
		padding: 0px;
	}

	.input-icon-container {
		display: flex;
		position: relative;
		align-items: center;
		gap: 1em;

		.info-icon {
			height: 1.25rem;
			width: 1.25rem;
		}
	}

	/* Delete Apartment Modal Design*/
	#del-apartment-modal {
		--width: fit-content;
		--min-width: 250px;
		--border-radius: 6px;
		--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
		--height: fit-content;
	}
	ion-modal#del-apartment-modal .wrapper {
		margin: 5%;
		text-align: center;
	}

	.del-modal-btn {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin: 5% auto;
	}

	.del-apart-icon {
		font-size: 56px;
	}

	.create-edit-label {
		@include h3;
	}

	.editImmoSpinner {
		width: 50px;
		height: 100px;
	}

	.create-edit-input input[type="number"]::-webkit-inner-spin-button,
	.create-edit-input input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
}
