@import "theme/mixin.scss";

ion-content.settings {
	ion-card-title {
		@include h2;
	}

	ion-input,
	ion-textarea,
	ion-label {
		@include h4;
	}
}

ion-label.prf-label {
	color: var(--ion-text-color, #000);
	font-weight: 600;
	font-size: 16px;
}

ion-item.subscription-item {
	@include h4;

	span:last-child {
		color: var(--ion-color-medium);
		&.subscription-txt {
			color: var(--ion-color-success);
		}
	}
}

ion-row.if-sub-plans {
	justify-content: center;

	ion-col {
		flex: 0 1 400px;
	}
}

ion-card.if-sub-plan {
	display: flex;
	flex-direction: column;

	ion-card-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	ion-card-title {
		@include h3;
		--color: #f45a2a !important;
	}

	.if-sub-plan-toggle {
		display: flex;
		gap: 0.5em;
		align-items: center;
		align-self: center;
		margin-bottom: 10px;
	}

	.if-sub-plan-price {
		@include h3;
		align-self: center;
	}

	p {
		margin-top: 1em;
	}

	li,
	p {
		@include h4;
	}
}

.user-account-delete-summary-message {
	background: #fcf1ef;
	color: black;
	padding: 10px;
	border: 1px solid #ffc0b5;
	border-radius: 6px;
	cursor: default;
	align-items: center;
}

.delete-message-wrapper {
	margin: 5%;
}

.delete-button-wrapper {
	float: right;
}
