@import "theme/mixin.scss";

ion-content.feedback {
	ion-card-title {
		@include h2;
	}

	ion-card-subtitle {
		@include h3;
	}
}

.feedback-input {
	width: 300px;
	margin-bottom: 10px;
}

.feedback-textarea {
	width: 1100px;
	margin-bottom: 30px;
}

.ion-item {
	margin-bottom: 16px;
}

.ion-button-container {
	display: flex;
	justify-content: flex-end;
}

.feedback-button {
	display: flex;
	align-items: flex-start;
	width: 150px;
}
