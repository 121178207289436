@import "theme/mixin.scss";

ion-searchbar.filter-search {
	padding-top: 10px;
	height: 64px;
}

ion-card-content.filter-select {
	ion-select {
		min-height: unset;
	}
}

.filter-table-container ion-button.submit {
	float: right;
}
